import { Pages } from '../../../domain/Pages';

import { getConfigValue } from '@util/config';

const newFooterUrls = getConfigValue('REACT_APP_NEW_FOOTER_URLS') === 'true';
const currentYear = new Date().getFullYear();

export default {
    links: [
        {
            text: 'Privacy Policy',
            href: newFooterUrls
                ? 'https://crugroup.com/en/policies-and-statements/privacy-policy/'
                : 'https://www.crugroup.com/privacy-policy',
        },
        {
            text: 'General Terms & Conditions',
            href: newFooterUrls
                ? 'https://crugroup.com/en/policies-and-statements/terms-and-conditions/'
                : 'https://www.crugroup.com/terms-and-conditions',
        },
        {
            text: 'Licence Terms & Conditions',
            path: Pages.terms,
        },
    ],
    copyright: `© ${currentYear} CRU International Ltd. All rights reserved`,
};
