import { FC } from 'react';

import { dasherize } from 'inflection';

import { MobileMenuContextProvider } from '../../../context/MobileMenuContext';

import { useHeaderService } from '../../../hooks/data/useHeaderService';
import { useDeviceContext } from '../../../hooks/useDeviceContext';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useHeader } from '../../../hooks/useHeader';

import { BurgerMenu } from './components/BurgerMenu/BurgerMenu';
import { HeaderLink } from './components/HeaderLink/HeaderLink';
import { NotificationsMenu } from './components/NotificationsMenu/NotificationsMenu';
import { SearchWidget } from './components/SearchWidget/SearchWidget';
import { UserMoniker } from './components/UserMoniker/UserMoniker';

import { Brand } from '../../Brand/Brand';
import { Link } from '../../Link/Link';
import './Header.scss';

const generateId = (title?: string | null) => {
    if (!title) {
        return '';
    }
    return `nav-menu-${dasherize(title).toLowerCase()}`;
};

export const Header: FC = () => {
    const { smallDevice } = useDeviceContext();
    const { headerRef, isActiveNavLink } = useHeader();
    const { data } = useHeaderService();
    const { chatAiEnabled } = useFeatureFlags();

    const links = (
        <nav className="main-nav">
            <ul className="main-nav__list">
                {data?.menu?.menuItems?.map((value, index) => {
                    const id = generateId(value.title);
                    return (
                        <li key={id} className="main-nav__list-item" id={id}>
                            <HeaderLink
                                {...value}
                                index={index}
                                isActive={isActiveNavLink(value.link)}
                            >
                                {value.title}
                            </HeaderLink>
                        </li>
                    );
                })}
                {chatAiEnabled && data && (
                    // FIXME: hardcoding and feature-flagging a nav menu item for /chat-ai until it's part of the menu service
                    <li className="main-nav__list-item" id="nav-menu-chat-ai">
                        <HeaderLink
                            index={1}
                            link="/chat-ai"
                            isActive={isActiveNavLink('/chat-ai')}
                            isEnabled={true}
                        >
                            Chat AI
                        </HeaderLink>
                    </li>
                )}
                <li className="main-nav__list-item" id="nav-menu-services">
                    <HeaderLink
                        index={1}
                        link="/cru-services"
                        isActive={isActiveNavLink('/cru-services')}
                        isEnabled={true}
                    >
                        CRU Services
                    </HeaderLink>
                </li>
            </ul>
        </nav>
    );

    return (
        <header className="header" ref={headerRef}>
            {smallDevice ? (
                <MobileMenuContextProvider>
                    <BurgerMenu>{links}</BurgerMenu>
                    <NotificationsMenu />
                    <Link
                        to="/"
                        renderAs="NavLink"
                        data-snowplow-group="site_navigation"
                    >
                        <Brand size="68px" />
                    </Link>
                    <UserMoniker userName={data?.userMoniker} />
                    <SearchWidget />
                </MobileMenuContextProvider>
            ) : (
                <>
                    <div className="header-logo" id="chw-header-log">
                        <Link
                            to="/"
                            renderAs="NavLink"
                            data-snowplow-group="site_navigation"
                        >
                            <Brand size="40px" />
                        </Link>
                    </div>
                    <div className="header__inner">
                        <div className="header__inner__left">{links}</div>
                        <div className="header__inner__right">
                            <SearchWidget />
                            <NotificationsMenu />
                            <UserMoniker userName={data?.userMoniker} />
                        </div>
                    </div>
                </>
            )}
        </header>
    );
};
